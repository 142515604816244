
import  { hostURL } from "./config";


export const loginUserAPI = hostURL+"login-user";
export const loginAPI = hostURL+"login";
export const logoutAPI = hostURL+"logout";
export const marginReportAPI = hostURL+"margin-report";
export const carrierPaymentsAPI = hostURL+"margin-report/carrier-payments";
export const receivedPaymentsAPI = hostURL+"margin-report/received-payment";
export const d3API = hostURL+"d3";
export const brokerMarginDetailAPI = hostURL+"broker-margin-detail";
export const brokerTotalMarginDetailAPI = hostURL+"broker-total-margin-detail";

export const tlMarginDetailAPI = hostURL+"tl-margin-detail";
export const tmMarginDetailAPI = hostURL+"tm-margin-detail";

export const arAgingReportAPI = hostURL+"ar/aging-report";
export const arCompaniesMarginAPI = hostURL+"ar/companies-margin";
export const arCompaniesAllAPI = hostURL+"ar/companies-all";
export const brokerInrPaymentsAPI = hostURL+"broker/broker-inr-payment";
export const brokerInrPaymentsDetailAPI = hostURL+"broker/broker-inr-payment/detail";

export const apAgingReportAPI = hostURL+"ap/aging-report";

export const dsrAPI = hostURL+"dsr";
export const brokerDsrAPI = hostURL+"broker/dsr";
export const brokerDsrTlAPI = hostURL+"broker/dsr/tl";
export const brokerDsrbrokersAPI = hostURL+"broker/dsr/broker";
export const brokerBusinessReportAPI = hostURL+"broker/broker-report";
export const brokerBusinessReportNonPerformerAPI = hostURL+"broker/broker-report-non-performer";
export const brokermonthlyPerformerAPI = hostURL+"broker/broker-monthly-performer";
export const brokermonthlyPerformerExportAPI = hostURL+"broker/broker-monthly-performer-export";
export const brokerMarginReportAPI = hostURL+"broker/broker-report-margin";
export const brokerMarginReportCommentAPI = hostURL+"broker/broker-report-margin-comment";
export const brokerMarginReportAddCommentAPI = hostURL+"broker/broker-report-margin-add-comment";
export const brokerMarginReportExportAPI = hostURL+"broker/broker-report-margin-export";

export const brokerDsrMonthlyAPI = hostURL+"broker/dsr-monthly";
export const carrierPaymentAPI = hostURL+"ap/carrier-payment";
export const apPaymentAPI = hostURL+"ap/payment";
export const apSetPaymentAPI = hostURL+"ap/set-payment";
export const apGetDisputesAPI = hostURL+"ap/get-disputes";
export const apGetPaymentAPI = hostURL+"ap/get-payment";
export const apDonePaymentAPI = hostURL+"ap/done-payment";
export const apVlUploadImageAPI = hostURL+"vl-upload-image";
export const apGetSinglePaymentAPI = hostURL+"ap/get-single-payment";
export const apDoneSinglePaymentAPI = hostURL+"ap/done-single-payment";
export const apHoldPaymentAPI = hostURL+"ap/hold-payment";
export const apDownloadCsvAPI = hostURL+"ap/download-csv";


// homepage API
export const homeGetEmployeeAPI = hostURL+"home/get-employee";
export const homeTodaysMarginAPI = hostURL+"home/todays-margin";
export const homeMonthlyMarginAPI = hostURL+"home/monthly-margin";
export const homeMonthlyMarginPreAPI = hostURL+"home/monthly-margin-pre";
export const homeMonthlyMarginLockedAPI = hostURL+"home/monthly-margin-locked";
export const homePaymentHistoryAPI = hostURL+"home/payment-history";
export const homeCashFlowAPI = hostURL+"home/cash-flow";
export const homeEfsAPI = hostURL+"home/efs";
export const homeAgingAPI = hostURL+"home/aging";


export const addQuoteData = hostURL+"addQuote";
export const getSingleQuoteAPI = hostURL+"get-single-quote";
export const updateQuoteAPI = hostURL+"update-quote";
export const getUserQuoteData = hostURL+"getUserQuoteData/";
export const deleteQuote = hostURL+"quote/delete/";