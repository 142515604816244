import React, { useState,useEffect,useRef } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "./style.css";
import $ from "jquery";
import DataTables from "datatables.net";

import { faDownload} from "@fortawesome/free-solid-svg-icons";
import 'datatables.net-buttons/js/buttons.html5.js'; // For Excel export
import BarsChart from "./barsChart";
import {arCompaniesMarginAPI,arCompaniesAllAPI} from "../../../../config/endpoints";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyCheckDollar } from '@fortawesome/free-solid-svg-icons';
import Select from "react-select";
import { utils, writeFile } from "xlsx";
// import  "../../ar/aging-report/";
{/* <FontAwesomeIcon icon="fa-brands fa-nfc-symbol" /> */}
export default function BusinessReport() {
  

  const [data, setData] = useState([]);
  const date = new Date();
  const [dateRange, setDateRange] = useState([new Date(date.getFullYear(), date.getMonth()-1, 1),new Date(date.getFullYear(), date.getMonth(),0)]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [options,setOptions] = useState([
   
  ]);
  const [startDate, endDate] = dateRange;

  const businessReport = ( id) => {
    
    // console.log(id);
  };
    useEffect(() => {
      // console.log(selectedOptions);
      setSelectedValues(selectedOptions.map(option => option.value));
      console.log(selectedValues);
      
    },[selectedOptions]);
    useEffect(() => {

    axios.post(arCompaniesAllAPI,{
        withCredentials:true,
        startDate:startDate,
        endDate:endDate
    })
      .then(function (response) {
        setOptions(response.data)
      })
      .catch(function (error) {
        console.log(error);
      });

    axios.post(arCompaniesMarginAPI,{
        withCredentials:true,
        startDate:startDate,
        endDate:endDate
    })
      .then(function (response) {
      //   if(selectedValues.length > 0){
      //     console.log('0000000000000000');
      //     console.log( response.data);
      //     console.log('0000000000000000');
          
      //   const filteredData = response.data.filter(item => selectedValues.includes(item.code_name));
      //   setData(filteredData);
      // }else{
        setData(response.data);
      // }
       
      })

      .catch(function (error) {
        console.log(error);
      });
    },[endDate]);

  

    const DataTable = DataTables(Window, $);
    const tableRef = useRef();

    // Sample options for the select box
   
  
    // Handle selection change
    const handleChange = (selected) => {
      setSelectedOptions(selected || []);
      console.log("Selected options:", selected);
    };
  useEffect(() => {
     new DataTable(tableRef.current, {
      // iDisplayLength: 1000,
      orderCellsTop: true,
      fixedHeader: true,
      dom: 'Bfrtip', // Add the buttons container to the table
      buttons: [
        {
          extend: 'excelHtml5', // Specifies that you want to export to Excel
          text: 'Export to Excel',
          className: 'btn btn-success' // You can customize the button style
        }
      ],
      initComplete: function () {

        $('.filters').remove();

        var clone = $('.dataTable thead tr')
        .clone(true)
        .addClass('filters');
        clone.children('th').removeClass('sorting');
        clone.children('th').removeClass('sorting_desc');
        clone.children('th').addClass('sorting_disabled');
        clone.children('th').wrapInner('<td />').contents().unwrap();
        clone.parent().contents().unwrap();
        clone.appendTo('.dataTable thead');
        $('#DataTables_Table_0_filter').remove();

        var api = this.api();

        // For each column
        api
            .columns()
            .eq(0)
            .each(function (colIdx) {
            
                // Set the header cell to contain the input element
                var cell = $('.filters td').eq(
                    $(api.column(colIdx).header()).index()
                );
                var title = $(cell).text();
                $(cell).html('<input type="text" class="dt-filter form-controll rounded border-1 w-75 m-auto" placeholder="' + title + '" style="width: 100%;" />');
                  // console.log(cell);
                // On every keypress in this input
                $(
                    'input',
                    $('.filters td').eq($(api.column(colIdx).header()).index())
                )
                    .off('keyup change')
                    .on('change', function (e) {
                      e.preventDefault();
                        // Get the search value
                        $(this).attr('title', $(this).val());
                        var regexr = '({search})'; //$(this).parents('th').find('select').val();

                        var cursorPosition = this.selectionStart;
                        // Search the column for that value
                        api
                            .column(colIdx)
                            .search(
                                this.value != ''
                                    ? regexr.replace('{search}', '(((' + this.value + ')))')
                                    : '',
                                this.value != '',
                                this.value == ''
                            )
                            .draw();
                    })
                    .on('keyup', function (e) {
                        e.stopPropagation();

                        $(this).trigger('change');
                        // $(this)
                        //     .focus()[0]
                        //     .setSelectionRange(cursorPosition, cursorPosition);
                    });
            })  ;
    },
      // searching: false,
      paging: false,
      order: [[ 3, 'desc' ]],
      data:selectedValues.length>0?data.data.filter(item => selectedValues.includes(item.code_name)):data.data,
      "destroy" : true,
      columns: [
        { title: "Company Name",data:function(d){return`<label class="text-light-primary">${d.company_name_old}</label>`;},name:'company_name_old'},
        { title: "Company Code", data:function(d){return`<label class="text-dark-info fw-light">${d.code_name}</label>`;},name:'code_name' },
        { title: "Address",data:function(d){return`<label class="text-secondary fw-light ">${d.address}</label>`;},name:'address'},
        { title: "Total Business", data:function(d){ return `<label class="text-dark ">$ ${Number(d.sum).toLocaleString('en-IN')}</label>`; },name:'sum' }
       ],
    });
  }, [data,selectedValues]);

  const exportToExcel = () => {
    const table = $(tableRef.current).DataTable();
    
    // Define custom column headers
    const columns = [
      "Company Name",
      "Company Code",
      "Address",
      "Total Business",
    ];
  
    // Define custom data mapping
    const tableData = table
      .rows({ search: "applied" }) // Filtered rows
      .data()
      .toArray()
      .map(row => [
        row.company_name_old, // Replace with the appropriate key or index for column 1
        row.code_name, // Replace with the appropriate key or index for column 2
        row.address, // Replace with the appropriate key or index for column 3
        row.sum, // Replace with the appropriate key or index for column 4
      ]);
  
    // Prepare data for Excel
    const excelData = [columns, ...tableData];
  
    // Create Excel workbook and worksheet
    const ws = utils.aoa_to_sheet(excelData);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "TableData");
  
    // Export file
    writeFile(wb, "custom_table_data.xlsx");
  };

  return (
            <div className="all_pages_body pt-3">
            
              <div className='container '>
                <div className="row ">
                  <div className="col-12 mt-3">
                    <div className="card bg-light text-dark border-0 shadow-sm p-2">
                      <div className="card-content">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-8">
                              <h3>Total Business In Current Year (Shipper Based)</h3>
                            </div>
                            <div className="col-4">
                              <div className="float-end">
                                <DatePicker 
                                  selectsRange={true}
                                  startDate={startDate}
                                  endDate={endDate}
                                  onChange={(update) => {
                                    console.log(update);
                                    setDateRange(update);
                                  }}
                                  isClearable={true}
                                  placeholderText="Enter From and To date"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-body ">
                          <BarsChart labels={data.companies??[]} series={data.vals??[]} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='container mt-3 py-3'>
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        
                      </div>
                    </div>
                    <div className="col-12">
                    
                      <div className="card border-0 shadow">
                        <div className="card-body  bg-white rounded table_align">
                          <div className="row">
                              <div className="col-md-4 align-self-center">
                                  <div className=" ms-3">
                                    {/* <button onClick={exportToExcel} className="btn btn-success rounded"><FontAwesomeIcon icon={faDownload} className="text-white mx-1" /> Export To Excel</button> */}
                                  </div>
                              </div>
                              <div className="col-md-8">
                              <div className="p-3 selector-section">
                              <div class="d-flex align-items-center">
                              
                                <div class="flex-shrink-0">
                                  <label>Search:</label>
                                </div>
                                
                                <div class="flex-grow-1 ms-3">
                                  <Select
                                    options={options}
                                    isMulti
                                    value={selectedOptions}
                                    onChange={handleChange}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Select options..."
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                            </div>
                       
                          <table className="text-left" ref={tableRef}></table>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
  )
}


