import React, { useState,useEffect } from "react";

import { Link } from "react-router-dom";
import { homeMonthlyMarginPreAPI,homeGetEmployeeAPI,homeTodaysMarginAPI,homeMonthlyMarginAPI,homeMonthlyMarginLockedAPI,homePaymentHistoryAPI,homeCashFlowAPI,homeEfsAPI,homeAgingAPI } from "../../../../config/endpoints";
import axios from "axios";

import MarginReport from "./charts/marginReport";
import DonutHalf from "./charts/donutHalf";
import LineChart from "./charts/lineChart";
import Pie from "./charts/pie";
import Colomn from "./charts/colomn";
import ColomnNoGrid from "./charts/colomnNoGrid";
import PaymentTable from "./tables/paymentTable";
import "./home.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faArrowTrendDown,faTruckFast,faFileInvoiceDollar,faMoneyBillTransfer,faHandHoldingDollar,faSackDollar,faMoneyCheckDollar,faCreditCard,faMoneyBillTrendUp,faArrowTrendUp} from "@fortawesome/free-solid-svg-icons";
import { color } from "d3";

const Home = () => {
  const [data, setData] = useState();
  const [month, setMonth] = useState('2023');
  const [day, setDay] = useState();
  const [todayMargin, setTodayMargin] = useState();
  const [monthlyMargin, setMonthlyMargin] = useState({series: [],labels:[]});
  const [monthlyMarginPre, setMonthlyMarginPre] = useState({series: [],labels:[]});
  const [monthlyMarginLocked, setMonthlyMarginLocked] = useState({series: [],labels:[]});
  const [aceMonthlyMargin, setAceMonthlyMargin] = useState({series: [],labels:[]});
  const [aceMonthlyMarginPre, setAceMonthlyMarginPre] = useState({series: [],labels:[]});
  const [aceMonthlyMarginLocked, setAceMonthlyMarginLocked] = useState({series: [],labels:[]});
  const [paymentHistory, setPaymentHistory] = useState({pie:[]});
  const [cashFlow, setCashFlow] = useState({series: []});
  const [aceCashFlow, setAceCashFlow] = useState({series: []});
  const [efs, setEfs] = useState({series: []});
  const [aging, setAging] = useState({series: []});

  useEffect(() => {
    
           
    axios.post(homePaymentHistoryAPI,{
      withCredentials:true,
      month:month,
    }).then(function (response) {
      setPaymentHistory(response.data);
      // console.log(paymentHistory);
    }).catch(function (error) {});

  },[month]);
  useEffect(() => {
      axios.post(homeTodaysMarginAPI,{
        withCredentials:true,
        day:day,
  }).then(function (response) {
      setTodayMargin(response.data.employee)
    }).catch(function (error) {});

    },[day]);
  useEffect(() => {

      axios.post(homeGetEmployeeAPI,{
          withCredentials:true,
        })
        .then(function (response) {
          setData(response.data)
          // console.log(data.employee[0].total);
        }).catch(function (error) {});

      
          
      // axios.post(homePaymentHistoryAPI,{
      //       withCredentials:true,
      //   }).then(function (response) {
      //       setPaymentHistory(response.data);
      //       // console.log(paymentHistory);
      //     }).catch(function (error) {});

      axios.post(homeMonthlyMarginAPI,{
            withCredentials:true,
        }).then(function (response) {
          setMonthlyMargin(
          {series: [
            {
              name: 'VL Shipper Amount',
              data: response.data?.totalShipperAmt??[]
            },
            {
              name: "VL Carrier Amount",
              data: response.data?.totalCarrierAmt??[]
            },
            {
              name: "VL Margin",
                data: response.data?.totalmargin??[] 
            },
            {
              name: "Total Shipments",
                data: response.data?.totalShipments??[] 
            }
            ],
            labels:response.data?.month??[]
          })
          setAceMonthlyMargin(
          {series: [
            {
              name: 'ACE Shipper Amount',
              data: response.data?.totalAceShipperAmt??[]
            },
            {
              name: "ACECarrier Amount",
              data: response.data?.totalAceCarrierAmt??[]
            },
            {
              name: "ACE Margin",
                data: response.data?.totalAceMargin??[] 
            },
            {
              name: "Total Shipments",
                data: response.data?.aceTotalShipments??[] 
            }
            ],
            labels:response.data?.month??[]
          })
          }).catch(function (error) {});
      axios.post(homeMonthlyMarginPreAPI,{
            withCredentials:true,
        }).then(function (response) {
          setMonthlyMarginPre(
          {series: [
            {
              name: 'VL Shipper Amount',
              data: response.data?.totalShipperAmt??[]
            },
            {
              name: "VL Carrier Amount",
              data: response.data?.totalCarrierAmt??[]
            },
            {
              name: "VL Margin",
                data: response.data?.totalmargin??[] 
            },
            {
              name: "Total Shipments",
                data: response.data?.totalShipments??[] 
            }
            ],
            labels:response.data?.month??[]
          })
          setAceMonthlyMarginPre(
          {series: [
            {
              name: 'ACE Shipper Amount',
              data: response.data?.totalAceShipperAmt??[]
            },
            {
              name: "ACECarrier Amount",
              data: response.data?.totalAceCarrierAmt??[]
            },
            {
              name: "ACE Margin",
                data: response.data?.totalAceMargin??[] 
            },
            {
              name: "Total Shipments",
                data: response.data?.aceTotalShipments??[] 
            }
            ],
            labels:response.data?.month??[]
          })
          }).catch(function (error) {});

      axios.post(homeMonthlyMarginLockedAPI,{
            withCredentials:true,
        }).then(function (response) {
          setMonthlyMarginLocked(
          {series: [
            {
              name: 'VL Shipper Amount',
              data: response.data?.totalShipperAmt??[]
            },
            {
              name: "VL Carrier Amount",
              data: response.data?.totalCarrierAmt??[]
            },
            {
              name: "VL Margin",
                data: response.data?.totalmargin??[] 
            },
            {
              name: "Total Shipments",
                data: response.data?.totalShipments??[] 
            }
            ],
            labels:response.data?.month??[]
          })
          setAceMonthlyMarginLocked(
          {series: [
            {
              name: 'ACE Shipper Amount',
              data: response.data?.totalAceShipperAmt??[]
            },
            {
              name: "ACE Carrier Amount",
              data: response.data?.totalAceCarrierAmt??[]
            },
            {
              name: "ACE Margin",
                data: response.data?.totalAceMargin??[] 
            },
            {
              name: "Total Shipments",
                data: response.data?.aceTotalShipments??[] 
            }
            ],
            labels:response.data?.month??[]
          })
          }).catch(function (error) {});

      axios.post(homeCashFlowAPI,{
            withCredentials:true,
        }).then(function (response) {
          setCashFlow(
          {series: [
            {
              name: 'Received',
              data: response.data?.sipperCashFlow??[]
            },
            {
              name: "Payout",
              data: response.data?.carrierCashFlow??[]
            }
            
            ],
            labels:response.data?.labels??[]
          })
          setAceCashFlow(
          {series: [
            {
              name: 'ACE Received',
              data: response.data?.aceSipperCashFlow??[]
            },
            {
              name: "ACE Payout",
              data: response.data?.aceCarrierCashFlow??[]
            },
            
            ],
            labels:response.data?.labels??[]
          })
          }).catch(function (error) {});

      axios.post(homeEfsAPI,{
            withCredentials:true,
        }).then(function (response) {
          setEfs(
          {series: [
            {
              name: "EFS",
              data: response.data?.carrierCashFlow??[]
            }
          ],
            labels:response.data?.labels??[]
          })
          }).catch(function (error) {});

      axios.post(homeAgingAPI,{
            withCredentials:true,
        }).then(function (response) {
          setAging(
          {series: [
            {
              name: "Amount",
              data: [response.data?.oneto30, response.data?.thirtyto60, response.data?.sixtyto90, response.data?.ninetyplus]
            }
          ],
          data:response.data
          })
          }).catch(function (error) {});


      
  },[]);
  function years() {
    const rows = [];
    for(let i =2021;i<2025;i++){
      rows.push(<option value={i} key={i} selected={(i==2023)?'selected':'false'}>{i}</option>);
      
    }

  return rows;
}


  return (
    <>
    <div className="main py-5 mb-100 home-body" > 
    <section>
      <div className="container">
        <div className="row">

          <div className="col-xxl-4 mb-4 order-5 order-xxl-0">
            <div className="card shadow-sm border-0">
              
              <div className="card-body">
                
                <div className="card-title mb-0">
                  <h5 className="m-0">Brokers</h5>
                </div>
                <div className="vehicles-overview-progress progress rounded-2 my-4" style={{"height": "46px"}}>
                 
                  <div className="progress-bar fw-medium text-start  text-dark px-3 rounded-0" role="progressbar" style={{"width": "25%","background":"#f76e5f"}} aria-valuenow="39.7" aria-valuemin="0" aria-valuemax="100">Brokers:{data?.broker}</div>
                  <div className="progress-bar fw-medium text-start  px-3" role="progressbar" style={{"width": "20%",'background':"#9e63f0"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">AR: {data?.ar}</div>
                  <div className="progress-bar fw-medium text-start  px-3" role="progressbar" style={{"width":"20%","background":"#73d298"}} aria-valuenow="20" aria-valuemin="0" aria-valuemax="100">AP: {data?.ap}</div>
                  <div className="progress-bar fw-medium text-start  px-2 rounded-0 px-lg-2 px-xxl-3" role="progressbar" style={{"width": "18%",'background':'#486e76'}} aria-valuenow="14.6" aria-valuemin="0" aria-valuemax="100">CMT: {data?.cmt}</div>
                  <div className="progress-bar fw-medium text-start  px-2 rounded-0 px-lg-2 px-xxl-3" role="progressbar" style={{"width": "17%",'background':'#338a9b'}} aria-valuenow="14.6" aria-valuemin="0" aria-valuemax="100">SME: {data?.sme}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-8 mb-4 col-lg-7 col-12">
            <div className="card h-100 shadow-sm border-0">
              
              <div className="card-body">
                <div className="d-flex justify-content-between mb-3">
                  <h5 className="card-title mb-0">Today's Invoice</h5>
                    <input name="month" type="date" className="py-0 rounded  date_input" value={day} onChange={(e)=>{setDay(e.target.value)}} />
                </div>
                <div className="row gy-3">
                  <div className="col-md-3 col-6">
                    <div className="d-flex align-items-center">
                      <div className="badge bg-danger bg-opacity-10 bg-gradient  rounded-pill bg-label-primary me-3 p-2">
                               <FontAwesomeIcon
                                icon={faTruckFast}
                                  className=" fs-4 p-1" style={{color:"#f76e5f"}}
                                />
                      </div>
                      <div className="card-info">
                        <h5 className="mb-0">{Number(todayMargin?.total).toLocaleString('en-IN')}</h5>
                        <small>Invoiced</small>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-6">
                    <div className="d-flex align-items-center">
                      <div className="badge bg-primary bg-opacity-10 rounded-pill bg-label-info me-3 p-2">
                        <FontAwesomeIcon 
                                  icon={faSackDollar}
                                  className="fs-4 p-1" style={{color:"#9e63f0"}}
                                />
                      </div>
                      <div className="card-info">
                        <h5 className="mb-0">${Number(todayMargin?.invoice_amt?.toFixed(2)??0).toLocaleString('en-IN')}</h5>
                        <small>Shipper Total</small>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-6">
                    <div className="d-flex align-items-center">
                      <div className="badge  bg-success bg-opacity-10 rounded-pill bg-label-info me-3 p-2">
                        <FontAwesomeIcon
                                  icon={faHandHoldingDollar}
                                  className="fs-4 p-1"  style={{color:"#73d298"}}
                                />
                      </div>
                      <div className="card-info">
                        <h5 className="mb-0">${Number(todayMargin?.carrier_total?.toFixed(2)??0).toLocaleString('en-IN')}</h5>
                        <small>Carrier Total</small>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-6">
                    <div className="d-flex align-items-center">
                      <div className="badge bg-success bg-opacity-10 rounded-pill bg-label-info me-3 " style={{padding: '9px 12px' }}>
                        <FontAwesomeIcon
                                  icon={faFileInvoiceDollar}
                                  className="fs-4 p-1 px-1"  style={{color:"#338a9b"}} 
                                />
                      </div>
                      <div className="card-info">
                        <h5 className="mb-0">${Number((todayMargin?.invoice_amt-todayMargin?.carrier_total)?.toFixed(2)).toLocaleString('en-IN')}</h5>
                        <small>Margin</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section >
      <div className="container mb-2">
        <div className="row">
          <div className="col-6" >
            <div className="card border-0 shadow-sm">
              <div className="card-body">
                <h4 className="card-title m-1">VL Monthly Invoiced Report</h4>
                <MarginReport series={monthlyMargin} labels={monthlyMargin.labels}  height={'300'}/>
                </div>
            </div>
            <div className="card border-0 shadow-sm mt-2">
              <div className="card-body">
                <h4 className="card-title m-1">VL Last year</h4>
                <MarginReport series={monthlyMarginPre} labels={monthlyMarginPre.labels}  height={'300'}/>
              </div>
            </div>
             
          </div>
          <div className="col-6" >
            <div className="card border-0 shadow-sm">

              <div className="card-body">
                <h4 className="card-title m-1"> ACE Monthly Invoiced Report</h4>
                <MarginReport series={aceMonthlyMargin} labels={aceMonthlyMargin.labels}  height={'300'}/>
                </div>
            </div>
            <div className="card border-0 shadow-sm mt-2">

              <div className="card-body">
                <h4 className="card-title m-1">ACE Last year</h4>
                <MarginReport series={aceMonthlyMarginPre} labels={aceMonthlyMarginPre.labels}  height={'300'}/>
              </div>
            </div>
             
          </div>
          {/* <div className="col-4" >
              
              <div className="row">
                
                  <div className="col-xl-12 mb-4 col-md-6">
                    
                        <input name="month" type="month" className="w-100 h-100 p-2 rounded form-control date_input" value={month} onChange={(e)=>{setMonth(e.target.value)}} />

                  </div>
              </div>
              <div className="row">

                <div className="col-xl-12 mb-4 col-md-6">
                  <div className="card border-0 shadow-sm">
                    <div className="card-body">
                      <div className="d-flex justify-content-between" style={{"position": "relative"}}>
                        <div className="d-flex flex-column">
                          <div className="card-title mb-auto">
                            <h5 className="mb-1 text-nowrap "> Payment History </h5>
                            <small style={{fontSize:'12px'}}>Invoice Based (Current Year)</small>
                          </div>
                          <div className="chart-statistics">
                            <ul>
                              <li style={{color:"#f76e5f"}}><small className="text-dark fw-600" style={{fontSize:'12px'}}>Short: ${Number(paymentHistory?.short?.balance_due?.toFixed(0)).toLocaleString('en-IN')}</small></li>
                              <li style={{color:"#9e63f0"}}><small className="text-dark fw-600" style={{fontSize:'12px'}}>Pending: ${Number(paymentHistory?.pending?.balance_due??0).toFixed(0).toLocaleString('en-IN')}</small></li>
                              <li style={{color:"#73d298"}}><small className="text-dark fw-600" style={{fontSize:'12px'}}>Aging: ${Number(paymentHistory?.aging?.balance_due?.toFixed(0)).toLocaleString('en-IN')}</small></li>
                              <li ><small className="text-dark fw-600" style={{fontSize:'12px'}}>Paid: {Number(paymentHistory?.paid?.invoice_amt?.toFixed(0)).toLocaleString('en-IN')}</small></li>
                              
                            </ul>
                          </div>
                        </div>
                        <div id="generatedLeadsChart" style={{"minHeight": "153.8px"}}>
                          
                        <Pie series={paymentHistory?.pie} labels={['Short','Pending','Aging']} /> 
                        </div>
                     
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6 mb-4 col-md-3 col-6">
                  <div className="card border-0 shadow-sm">
                    <div className="card-header pb-0 bg-white border-0">
                      <h5 className="card-title mb-0">${Number(paymentHistory?.badebt?.balance_due).toLocaleString('en-IN')}</h5>
                      <small className="text-muted">Bad Debt</small>
                    </div>
                    <div className="card-body" style={{"position": "relative"}} >
                      <div id="expensesChart" style={{"height": "120px"}} >
                        <DonutHalf series={[30]} value={Number(((paymentHistory?.badebt?.balance_due*100)/paymentHistory?.total?.invoice_amt).toFixed(0)).toLocaleString('en-IN')}  labels={['Bad Debts ']} />
                      </div>
                        <div className="mt-md-2 text-center mt-lg-3 mt-3">
                          <small className="text-muted mt-3">payement is not Paid from 180+ Days in Current Year</small>
                        </div>
                    </div>
                  </div>
                </div>


                <div className="col-xl-6 mb-4 col-md-3 col-6">
                  <div className="card border-0 shadow-sm">
                    <div className="card-header pb-0 bg-white border-0">
                      <h5 className="card-title mb-0">$ {Number(paymentHistory?.writeOffTotal).toLocaleString('en-IN')}</h5>
                      <small className="text-muted">Writeoff</small>
                    </div>
                    <div className="card-body" style={{"position": "relative"}} >
                      <div id="expensesChart" style={{"height": "147px"}} >
                        <LineChart series={[{type:'line', name:'Amount',show: false,data: paymentHistory?.writeOff}]} labels={paymentHistory?.writeOfflables} />
                      </div>
                        <div className="mt-md-2 text-center mt-lg-3 mt-3">
                          <small className="text-muted mt-3">$ {paymentHistory?.writeOffTotal} Amount is writeOff In Current Year</small>
                        </div>
                    </div>
                  </div>
                </div>

                

              </div>

          </div> */}

        </div>

      </div>
    </section>

    <section>
      <div className="container">
        <div className="row">
          <div className="col-12">

            <div className="card border-0 shadow-sm ">
              <div className="card-header d-flex justify-content-between bg-white border-0">
                <div className="card-title mb-0">
                  <h5 className="mb-0">Reports</h5>
                  {/* <small className="text-muted">Yearly  Earnings Overview</small> */}
                </div>
                <div className="dropdown">
                  <button className="btn p-0" type="button" id="earningReportsTabsId" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="ti ti-dots-vertical ti-sm text-muted"></i>
                  </button>
                  <div className="dropdown-menu dropdown-menu-end" aria-labelledby="earningReportsTabsId" >
                    <a className="dropdown-item" href="javascript:void(0);">View More</a>
                    <a className="dropdown-item" href="javascript:void(0);">Delete</a>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <ul className="nav nav-tabs widget-nav-tabs pb-3 gap-4 mx-1 d-flex flex-nowrap border-0 multinav-chart" role="tablist">
                      <li className="nav-item" role="presentation">
                        <a href="javascript:void(0);" className=" btn d-flex flex-column px-4 py-2 align-items-center justify-content-center active " role="tab" data-bs-toggle="tab" data-bs-target="#navs-orders-id" aria-controls="navs-orders-id" aria-selected="false" tabIndex="-1">
                          <div className="badge bg-danger bg-opacity-10 bg-gradient   bg-label-primary  ">
                              <FontAwesomeIcon
                                icon={faSackDollar}
                                className=" fs-4 p-1" style={{color:"#f76e5f"}}
                              />
                          </div>
                          <h6 className="tab-widget-title mb-0 mt-2 text-dark">Cash Flow</h6>
                        </a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a href="javascript:void(0);" className=" btn d-flex flex-column px-4 py-2 align-items-center justify-content-center  " role="tab" data-bs-toggle="tab" data-bs-target="#navs-locked-id" aria-controls="navs-locked-id" aria-selected="false" tabIndex="-1">
                          <div className="badge bg-success bg-opacity-10 bg-gradient   bg-label-success  ">
                              <FontAwesomeIcon
                                icon={faMoneyBillTransfer}
                                className=" fs-4 p-1" style={{color:"#73d298"}}
                              />
                          </div>
                          <h6 className="tab-widget-title mb-0 mt-2 text-dark">Locked Margin</h6>
                        </a>
                      </li>
                      
                      <li className="nav-item" role="presentation">
                        <a href="javascript:void(0);" className=" btn d-flex flex-column px-4 py-2 align-items-center justify-content-center  "  role="tab" data-bs-toggle="tab" data-bs-target="#navs-sales-id" aria-controls="navs-sales-id" aria-selected="true">
                          <div className="badge bg-primary bg-opacity-10 bg-gradient   bg-label-primary  ">
                              <FontAwesomeIcon
                                icon={faMoneyBillTrendUp}
                                className=" fs-4 p-1" style={{color:"#9e63f0"}}
                              />
                          </div>
                          <h6 className="tab-widget-title mb-0 mt-2 text-dark "> EFS </h6>
                        </a>
                      </li>
                      {/* <li className="nav-item" role="presentation">
                        <a href="javascript:void(0);" className=" btn d-flex flex-column px-4 py-2 align-items-center justify-content-center "role="tab" data-bs-toggle="tab" data-bs-target="#navs-profit-id" aria-controls="navs-profit-id" aria-selected="false" tabindex="-1">
                          <div className="badge bg-info bg-opacity-10 bg-gradient   bg-label-primary  ">
                              <FontAwesomeIcon
                                icon={faCreditCard}
                                className=" fs-4 p-1" style={{color:"#73d298"}}
                              />
                          </div>
                          <h6 className="tab-widget-title mb-0 mt-2 text-dark">Profit</h6>
                        </a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a href="javascript:void(0);" className=" btn d-flex flex-column px-4 py-2 align-items-center justify-content-center "   role="tab" data-bs-toggle="tab" data-bs-target="#navs-income-id" aria-controls="navs-income-id" aria-selected="false" tabindex="-1">
                          <div className="badge bg-success bg-opacity-10 bg-gradient   bg-label-primary  ">
                              <FontAwesomeIcon
                                icon={faMoneyCheckDollar}
                                className=" fs-4 p-1" style={{color:"#338a9b"}}
                              />
                          </div>
                          <h6 className="tab-widget-title mb-0 mt-2 text-dark">Income</h6>
                        </a>
                      </li> */}
                    </ul>
                    <div className="tab-content p-0 ms-0 ms-sm-2">
                      <div className="tab-pane fade active show" id="navs-orders-id" role="tabpanel" style={{'position': 'relative'}} >
                        <div className="row">
                          <div className="col-6 ">
                            <Colomn series={cashFlow.series} labels={cashFlow.labels} />
                          </div>
                          <div className="col-6">
                            <Colomn series={aceCashFlow.series} labels={aceCashFlow.labels} />
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade " id="navs-locked-id" role="tabpanel" style={{'position': 'relative'}} >
                        <div className="row">
                          <div className="col-6 ">
                            <MarginReport series={monthlyMarginLocked} labels={monthlyMarginLocked.labels}  height={'290'}/>
                          </div>
                          <div className="col-6">
                            <MarginReport series={aceMonthlyMarginLocked} labels={aceMonthlyMarginLocked.labels}  height={'290'}/>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade " id="navs-sales-id" role="tabpanel" style={{'position': 'relative'}} >
                      <MarginReport  series={efs} labels={efs.labels} height={'300'}  />
                      </div>
                      {/* <div className="tab-pane fade" id="navs-profit-id" role="tabpanel" style={{'position': 'relative'}}>
                      <LineChart series={[12,33,55,66]} labels={paymentHistory?.writeOfflables} />
                      </div> */}
                      <div className="tab-pane fade" id="navs-income-id" role="tabpanel" style={{'position': 'relative'}}>
                      {/* <Colomn /> */}
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-4 border-start">

                      <div className="text-center ">
                        <div className="dropdown">
                          <select className="border-0  bold px-5" type="button" style={{outlineColor: '#9e63f0',outline: '2px solid #9e63f0',color:'#9e63f0',borderRadius: '3px'}}  >
                            <option value=""><b>Select Year</b></option>
                            <option value="2023"><b>2023</b></option>
                            <option value="2022"><b>2022</b></option>
                            <option value="2021"><b>2021</b></option>
                          </select>
                          
                        </div>
                      </div>
                      <h3 className="text-center pt-4 mb-0">$25,825</h3>
                      <p className="mb-4 text-center"><span className="fw-medium">Budget: </span>56,800</p>
                      <div className="px-3" style={{"position": "relative"}}>
                        <div id="budgetChart" style={{"min-height":" 115px"}}>
                        <LineChart  series={[{type:'line',show: false,data: paymentHistory?.writeOff}]} labels={paymentHistory?.writeOfflables}  />
                        </div>
                      </div>
                      <div className="text-center mt-4">
                        <button type="button" className="btn  waves-effect waves-light" style={{background:'#9e63f0',color:'white',paddingBottom:'10px'}}>Increase Budget</button>
                      </div>

                  </div>   */}

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>

    <section>
      <div className="container mt-5">
        <div className="row">
          <div className="col-xl-3 col-md-6 mb-4">
            <div className="card  shadow-sm border-0">
              <div className="card-header d-flex justify-content-between bg-white">
                <div className="card-title mb-0">
                  <h5 className="m-0 me-2">Aging Chart</h5>
                  <small className="text-muted">Total Pading payments period wise</small>
                </div>
                <div className="dropdown">
                  <button className="btn p-0" type="button" id="earningReports" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="ti ti-dots-vertical ti-sm text-muted"></i>
                  </button>
                  <div className="dropdown-menu dropdown-menu-end" aria-labelledby="earningReports">
                    <a className="dropdown-item" href="javascript:void(0);">Download</a>
                    <a className="dropdown-item" href="javascript:void(0);">Refresh</a>
                    <a className="dropdown-item" href="javascript:void(0);">Share</a>
                  </div>
                </div>
              </div>
              <div className="card-body " style={{'position': 'relative'}} >
              
                <ul className="p-0 m-0">
                  <li className="d-flex mb-3">
                    <div className="avatar flex-shrink-0 me-3">
                      <span className="badge bg-danger bg-opacity-10 bg-label-info text-success">
                        <FontAwesomeIcon icon={faArrowTrendUp} className="fs-5 p-1" style={{color: "#f76e5f"}} />  
                      </span>
                    </div>
                    <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                      <div className="me-2">
                        <h6 className="mb-0">1 TO 30</h6>
                        
                      </div>
                      <div className="user-progress d-flex align-items-center gap-3">
                        <small>${Number(aging?.data?.oneto30).toLocaleString('en-IN')}</small>
                        
                      </div>
                    </div>
                   </li>
                  <li className="d-flex mb-3">
                    <div className="avatar flex-shrink-0 me-3">
                      <span className="badge bg-primary bg-opacity-10  bg-label-info text-info ">
                      <FontAwesomeIcon icon={faMoneyBillTrendUp} className="fs-4 p-1" style={{color: "#9e63f0"}} />  
                      </span>
                    </div>
                    <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                      <div className="me-2">
                        <h6 className="mb-0">30 TO 60</h6>
                      </div>
                      <div className="user-progress d-flex align-items-center gap-3">
                        <small>${Number(aging?.data?.thirtyto60).toLocaleString('en-IN')}</small>
                      </div>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="avatar flex-shrink-0 me-3">
                      <span className="badge bg-success bg-opacity-10 bg-label-info text-warning">
                      <FontAwesomeIcon icon={faMoneyBillTransfer} className="fs-5 p-1" style={{color: "#73d298"}} />  
                      </span>
                    </div>
                    <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                      <div className="me-2">
                        <h6 className="mb-0">60 TO 90</h6>
                      </div>
                      <div className="user-progress d-flex align-items-center gap-3">
                        <small>${Number(aging?.data?.sixtyto90).toLocaleString('en-IN')}</small>
                      </div>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="avatar flex-shrink-0 me-3">
                      <span className="badge bg-info bg-opacity-10 bg-label-info text-success">
                        <FontAwesomeIcon icon={faArrowTrendUp} className="fs-4 p-1" style={{color: "#338a9b"}} />  
                      </span>
                    </div>
                    <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                      <div className="me-2">
                        <h6 className="mb-0">90+</h6>
                      </div>
                      <div className="user-progress d-flex align-items-center gap-3">
                        <small>${Number(aging?.data?.ninetyplus).toLocaleString('en-IN')}</small>
                      </div>
                    </div>
                  </li>
                </ul>
                <div id="reportBarChart" style={{"minHeight": "219px"}}>
                  <ColomnNoGrid  series={aging.series} labels={['1-30','30-60','60-90','90+']}/>
                </div>
              </div>
            </div>
          </div>

          <div className="col-9">
              <div className="card shadow-sm border-0">
                <div className="card-header bg-white">
                  <div className="card-title mb-0">
                    <h5 className="m-0 me-2">Aging Report</h5>
                    <small className="text-muted">Panding payment 90+ days</small>
                  </div>
                </div>
                <div className=" payment-table dataTable-wrap" style={{'position': 'relative'}} >
                  <PaymentTable pdata={aging?.data??{data:[]}} />
                </div>
              </div>
          </div>


        </div>
      </div>
    </section>



    </div>
    </>
  );
};

export default Home;


          