import React, { useState,useEffect,useRef } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import { Modal, Button } from "react-bootstrap";

import $ from "jquery";
import DataTables from "datatables.net";
// import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css"; // Core DataTable styling
import "datatables.net-buttons-dt/css/buttons.dataTables.css"; // Buttons styling
import "datatables.net-buttons";
import "datatables.net-buttons/js/buttons.html5.min"; // HTML5 export buttons
import "jszip"; // Required for Excel export functionality
import "datatables.net-buttons/js/buttons.html5.min";
import {brokerMarginReportCommentAPI,brokerMarginReportAddCommentAPI,brokerMarginReportAPI,brokerMarginReportExportAPI,brokerBusinessReportNonPerformerAPI} from "../../../config/endpoints";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie } from '@fortawesome/free-solid-svg-icons';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';

import  './style.css';
{/* <FontAwesomeIcon icon="fa-brands fa-nfc-symbol" /> */}


export default function MarginReport() {
  
  const [data, setData] = useState([]);
  const [nonPerformer, setNonPerformer] = useState([]);
  const [type, setType] = useState(0);
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState('desc');
  const [range, setRange] = useState("-100000,100000");
  const date = new Date();
  const [dateRange, setDateRange] = useState([ new Date(date.getFullYear(), 0, 1),new Date(date.getFullYear(), date.getMonth(),0)]);
  const [startDate, endDate] = dateRange;
  const [changes, setChanges] = useState(0);

  const [excelProgress, setExcelProgress] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const businessReport = ( id) => {
    
    // console.log(id);
  };
    useEffect(() => {
      if(startDate && endDate){
        setLoading(true)
    axios.post(brokerMarginReportAPI,{
      withCredentials:true,
      startDate:startDate,
      endDate:endDate,
      order:order,
      range:range,
      type: type
  })
      .then(function (response) {
        setLoading(false)

        // if(response.data.length>0)
        setData(response.data?.filteredIncentives??[])
        setNonPerformer(response.data?.nonPerforming??[])
        // alert(2222222);
          // console.log(response.data.length);
      })

      .catch(function (error) {
        setLoading(false)

        console.log(error);
      });
    }
    },[endDate,order,range,type,changes]);

  //   useEffect(() => {
   
  //   axios.post(brokerBusinessReportNonPerformerAPI,{
  //     withCredentials:true,
  //   startDate:startDate,
  //   endDate:endDate,
  //   order:order,
  //   range:range
  // })
  //     .then(function (response) {
  //       setLoading(false)
  //       console.log(response.data);
        
  //       setNonPerformer(response.data??[])
  //     })

  //     .catch(function (error) {
  //       setLoading(false)

  //       console.log(error);
  //     });
  //   },[endDate]);

    const downloadXlxs = () => {
      setExcelProgress(true);
      if (startDate && endDate) {
        // Make the POST request with the required data
        axios.post(brokerMarginReportExportAPI, {
          withCredentials:true,
          startDate: startDate,
          endDate: endDate,
          order: order,
          range: range,
          // responseType: 'arraybuffer'
        },{
          responseType: 'blob', // Ensures the response is treated as binary data
      })
        .then((response) => {
          // Create a Blob from the response data
      setExcelProgress(false);

          const blob = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
          const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'Incentive_Report.xlsx';
            document.body.appendChild(link);
            link.click();
            URL.revokeObjectURL(link.href);
            document.body.removeChild(link);
        })
        .catch((error) => {
          console.error("Error downloading the file:", error);
        });
      }
    }

    const DataTable = DataTables(Window, $);
    const tableRef = useRef();

    useEffect(() => {
      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy();
      }
    
      // Base columns
      const columns = [
        {
          title: "First/Last Name",
          data: null, // Use null if you need custom rendering
          render: (data, type, row) => {
            return `${row.first_name??''} ${row.last_name??''}`;
          },
          name: "first_name",
        },
        { title: "Total Margin", data: "total_margin", name: 'total_margin' },
        { title: "Total Months", data: d => Number(d.totalMonths).toLocaleString('en-IN'), name: 'totalMonths' },
        { title: "Working Months", data: d => Number(d.workingMonths).toLocaleString('en-IN'), name: 'workingMonths' },
        { title: "Average Margin", data: d => `$${parseInt(parseInt(d.total_margin) / parseInt(d.totalMonths))}`, name: 'avrageMargin' },
        { title: "NON Performing", data: d => d.totalMonths-d.workingMonths, name: 'totalMonths' },
        { title: "NON performing Average", data: d => `${parseInt((parseInt((d.totalMonths-d.workingMonths)) * 100) / parseInt(d.totalMonths))}%`, name: 'mm' },
        { title: "Last Comment", data: d => d.latestComment, name: 'latestComment' }
      ];
    
      // Initialize DataTable
      const tbl = new DataTable(tableRef.current, {
        // dom: "Bfrtip",
        searching: true,
        paging: false,
        order: [[0, 'desc']],
        data: data,
        destroy: true,
        buttons: [
          {
            extend: "excelHtml5",
            text: "Export as Excel",
            className: "btn btn-success",
            exportOptions: { columns: ":visible, :hidden" },
            title: "Data Export",
            filename: "Data_Export",
          },
        ],
        columns: columns,
      });
    
      // Append buttons to specific location
      $(tbl).DataTable().buttons().container().appendTo("#example_wrapper .col-md-6:eq(0)");
      $(tableRef.current).on("click", "tbody tr", function () {
        const row = $(this).closest("tr"); // Get the closest parent tr of the clicked td
        const rowData = tbl.row(row).data(); // Get the row data using the tr reference
    
        console.log("Clicked Row Data:", rowData);
    
        // Call your custom function with the row data
        handleRowClick(rowData);
      });
    
      // Cleanup function
      return () => {
        $(tableRef.current).off("click", "tbody tr"); // Remove click listeners
        tbl.destroy(); // Destroy the DataTable instance
      };
    }, [data]);

    const handleRowClick = (user) => {
      setSelectedUser(user);
      setModalVisible(true);
    };
  
  return (
            <div className="all_pages_body pt-3 mb-100">
            
              <div className='container '>
                <div className="row ">
                <div className="col-12 mt-3">
                  <div className="card bg-light text-dark border-0 shadow-sm p-2">
                    <div className="card-content">
                      <div className="card-body">
                          <div className="row">
                            <div className="col-7">
                              <h3>Broker Margin Report</h3>
                              <button className="btn btn-outline-dark btn-sm" onClick={downloadXlxs} disabled={excelProgress}>{excelProgress?(
                                <>
                                  <div class="spinner-border" role="status">
                                  <span class="visually-hidden">Loading...</span>
                                  </div>
                                </>):'Download XLXS Export'}
                              </button>
                            </div>
                            <div className="col-5">
                              <div className="float-end">
                                  <select name="" className="mx-2 selector_input" value={type} onChange={(event)=>{setType(event.target.value)}}>
                                    <option value={''}>ALL</option>
                                    <option value={0}>OTR</option>
                                    <option value={1}>DRAYAGE</option>
                                  </select>
                                  <select name="" className="mx-2 selector_input" value={range} onChange={(event)=>{setRange(event.target.value)}}>
                                    <option value={[-100000,100000]}>ALL</option>
                                    <option value={[0,1000]}>Below 1k</option>
                                    <option value={[1000,5000]}>1k-5k</option>
                                    <option value={[5000,10000]}>5k-10k</option>
                                    <option value={[10000,20000]}>10k-20K</option>
                                    <option value={[20000,50000]}>20k-50K</option>
                                    <option value={[50000,100000]}>50k+</option>
                                  </select>
                                <div className="date_picker_section">
                                  <FontAwesomeIcon className="date_picker_icon" icon={faCalendarDays} />
                                  <DatePicker
                                      selectsRange={true}
                                      startDate={startDate}
                                      endDate={endDate}
                                      onChange={(update) => {
                                        console.log(update);
                                        setDateRange(update);
                                      }}
                                      isClearable={true}
                                      placeholderText="Enter From and To date"
                                      className="date_selector_input"
                                      dateFormat="MM/yyyy"           // Format to display month and year only
                                      showMonthYearPicker            // Limits picker to month and year selection only
                                    />
                                </div>
                                
                                
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                 
                 
                </div>
              </div>

              <div className='container mt-3 py-3'>
                  <div className="row">
                    <div className="col-12">
                    
                      <div className="card border-0 shadow">
                        <div className="card-body bg-white rounded table_align">
                          <div className="row">
                            <h3>Non Performer Report</h3>
                            {nonPerformer.length<1?"No Data Found":nonPerformer.map((event,i)=>{
                                return (<>
                                  <div className="col-2 " key={i}>
                                    <div className="card business_broker_card my-2"  style={{borderRadius:'0px'}}>
                                      <div className="card-content">
                                        <div className="card-body p-1">
                                          <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0">
                                            <FontAwesomeIcon className="business_load_card_icon text-danger align-self-center mr-3" icon={faChartPie} />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                              <h5 className="business_load_title">{event.first_name}</h5><small style={{ fontSize:'8px',display: 'block', whiteSpace: 'nowrap', width: '100px', overflow: 'hidden',textOverflow:'ellipsis'}}>{event.email}</small>
                                              <p className="mb-0 mt-1 text-danger"> <span className="business_broker_loads"  style={{ fontSize:'8px' }} >Total Margin ${event.total??0}</span></p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>)
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
              <div className='container mt-3 py-3'>
                  <div className="row">
                    <div className="col-12">
                    
                      <div className="card border-0 shadow">
                        <div className="card-body bg-white rounded table_align">
                          <>
                          
                            <div   style={{width:'100%', display:(loading?'block':'none')}} >
                             <table>
                              <tr>
                                <th>First/Last Name</th>
                                <th>Total Margin</th>
                                <th>Working Months</th>
                                <th> Average Margin</th>
                                <th>NON Performing</th>
                                <th>NON performing Average</th>
                                <th>Last Comment</th>
                              </tr>
                              <tr>
                                <td colSpan={7} style={{textAlign:'center'}}>
                                  <div class="spinner-border" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                  </div>
                                </td>
                              </tr>
                             </table>
                            </div>
                         
                        			

                          <table className="text-left" ref={tableRef} style={{width:'100%', display:(loading?'none':'block')}}  onRowClick={handleRowClick}  ></table> 
                          </>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Comment Modal */}
                  {selectedUser && (
                    <CommentModal
                      userId={selectedUser?.user_id}
                      userName={`${selectedUser?.first_name} ${selectedUser?.last_name}`}
                      show={modalVisible}
                      onClose={() => setModalVisible(false)}
                      setChanges = {setChanges}
                      changes = {changes}
                    />
                  )}


              </div>
            </div>
  )
}



const CommentModal = ({ userId, userName, show, onClose,setChanges,changes }) => {
  const [newComment, setNewComment] = useState("");
  const [comments, setComments] = useState([]);

  useEffect(() => {
    if (userId) {
      // Fetch comments for the selected user
      axios
        .get(`${brokerMarginReportCommentAPI}/${userId}`, {
        })
        .then((response) => {
          setComments(
            response.data.sort((a, b) => new Date(b.date) - new Date(a.date))
          );
        })
        .catch((error) => {
          console.error("Error fetching comments:", error);
        });
    }
  }, [userId]);

  const handleAddComment = () => {
    if (!newComment.trim()) return;

    axios
      .post(`${brokerMarginReportAddCommentAPI}/${userId}`, { comment: newComment })
      .then((response) => {
        if(response.data?.result){
          setComments([response.data?.result, ...comments]);
          setNewComment("");
          setChanges(changes+1);
          onClose();
        }
      })
      .catch((error) => {
        console.error("Error adding comment:", error);
      });
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title className="fs-6">Comments for {userName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-end">
          <textarea
            className="form-control mb-3 textarea_input"
            placeholder="Add a comment"
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            style={{fontSize: '13px'}}
          />
          <Button className="btn custom_btn_primary btn-danger" onClick={handleAddComment}>
            Add Comment
          </Button>
        </div>
        <hr />
        <h5 className="fs-6">All Comments</h5>
        <ul className="list-group">
          {comments.map((comment, index) => (
            <li key={index} className="list-group-item">
              {comment.comment} <small>({new Date(comment.created_at).toLocaleString()})</small>
            </li>
          ))}
        </ul>
      </Modal.Body>
    </Modal>
  );
};



